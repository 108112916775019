

































































































































































































import { Component, Vue } from 'vue-property-decorator';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import OperationsApi from '@/api/bulkOperations';
import ActionText from '@/components/MonitorManager/ActionText.vue';
import ConfirmMessage from '@/components/WindowMethods/ConfirmMessage.vue';
import BaseModal from '@/components/BaseModal.vue';
import BulkOperationsMatchDetailsWithEntityType from '@/components/BulkOperationsMatchDetailsWithEntityType.vue';
import Client from '@/models/Client';
import { IPortfolioBulkOperationsTableWithEntityTypeDto } from '@/interfaces/dto/IPortfolioBulkOperationsTableWithEntityTypeDto';

@Component({
    components: {
        LoadingOverlay,
        ActionText,
        ConfirmMessage,
        BaseModal,
        BulkOperationsMatchDetailsWithEntityType,
    },
})

export default class BulkOperations extends Vue {
    get uploadText (): string {
        if (this.selectedUploadFileName) {
            return `Upload ${this.selectedUploadFileName}`;
        } else {
            return 'No file selected to upload';
        }
    }

    public get client (): Client | null {
        return this.$store.state.selectedScheme ?? null;
    }

    public showConfirmMessage = false;

    public isCalculationDataFeatureLive: boolean = process.env.VUE_APP_FEATURE_CALCULATION_DATA_IS_LIVE === 'true';

    public bulkOperations = [
        { name: 'Bulk Upload', icon: 'file-upload' },
        { name: 'Bulk Download', icon: 'file-download' },
        { name: 'Template Download', icon: 'file-excel' },
    ];

    public calculationOperations = [
        { name: 'Calculation Data Download', icon: 'file-download' },

    ];

    public selectedOperation = '';

    public apiStatus: null | number = null;

    public apiUserMessage = '';

    public successLegacyUploadStatusCode = 210; //represent a successful upload using a legacy version of the template

    public invalidLegacyUploadStatusCode = 422; //represent an unsuccessful upload using an unsupported version of the template

    public showLegacyUploadMessage = false;

    public templateDownloadFile = 'Master Monitor and Visualise Data Load Sheet v5.7.1';

    get legacyUploadMessage (): string {
        if (this.apiStatus === this.successLegacyUploadStatusCode){
            return `You have used a legacy version of the bulk upload spreadsheet to upload data.
            Please be aware there is a new bulk upload spreadsheet available <i>(${this.templateDownloadFile})</i> under the Template Download tab.
            It is recommended that you import the data into the new version for future use.`;
        }
        else if (this.apiStatus === this.invalidLegacyUploadStatusCode){
            return `You have used an unsupported version of the bulk upload spreadsheet to upload data.
            Please download and use the new bulk upload spreadsheet available <i>(${this.templateDownloadFile})</i> under the Template Download tab.`;
        }
        return '';
    }

    get legacyUploadHeading (): string {
        if (this.apiStatus === this.successLegacyUploadStatusCode){
            return 'Bulk Upload Successful';
        }
        else if (this.apiStatus === this.invalidLegacyUploadStatusCode){
            return 'Bulk Upload Unsuccessful';
        }
        return '';
    }

    closeConfirmPopUp (): void {
        this.showConfirmMessage = false;
    }

    resetUploadFile (): void {
        this.selectedUploadFile = null;
        this.selectedUploadFileName = '';
        const fileRef = this.$refs.file as unknown as HTMLInputElement;
        fileRef.value = '';
    }


    public clientId = '';


    pendingPortfolioId = -1

    bulkUploadTable: IPortfolioBulkOperationsTableWithEntityTypeDto | null = null

    isLoadingTable = true

    async getTableData (): Promise<void> {
        if (!this.clientId || this.pendingPortfolioId === -1) {
            return;
        }
        const payload = {
            clientId: this.clientId,
            portfolioId: this.pendingPortfolioId,
        };
        try {
            this.isLoadingTable = true;
            this.bulkUploadTable = (await OperationsApi.getBulkUploadTableWithEntityType(payload));
        } catch (error) {
            const message = error?.response?.data?.Message;
            if (message) {
                this.$store.dispatch('pushNetworkErrorMessage', message);
            }
        } finally {
            this.isLoadingTable = false;
        }
    }

    created (): void {
        this.clientId = this.$store.state.selectedScheme?.clientEntityID;
        this.pendingPortfolioId = this.$store.state.selectedScheme?.pendingPortfolioId ?? -1;
        if (!this.clientId || this.pendingPortfolioId === -1) {
            this.$router.push({ name: 'Client' });
            return;
        }
        this.getTableData();
    }

    public showCopyToast = false;

    public isFetchingData = false;

    public selectedUploadFile: File = null;

    public selectedUploadFileName = '';

    public response = '';

    confirmSubmitFile (): void {
        this.showConfirmMessage = true;
    }

    uploadFile (): void {
        const fileRef = this.$refs.file as unknown as HTMLInputElement;
        if (fileRef.files[0]) {
            this.selectedUploadFile = fileRef.files[0];
            this.selectedUploadFileName = fileRef.files[0].name;
        } else {
            this.resetUploadFile();
        }
    }

    async downloadCalculationDataFile (): Promise<void> {
        this.isFetchingData = true;
        OperationsApi.downloadCalculationsFile().finally(() => (this.isFetchingData = false));
    }

    async downloadPendingFile (): Promise<void> {
        this.isFetchingData = true;
        OperationsApi.downloadPendingFile().finally(() => (this.isFetchingData = false));
    }

    async downloadLiveFile (): Promise<void> {
        this.isFetchingData = true;
        OperationsApi.downloadLiveFile().finally(() => (this.isFetchingData = false));
    }

    public isSetTimeout = false;


    async submitFile (): Promise<void> {
        this.showConfirmMessage = false;
        this.isFetchingData = true;
        this.apiUserMessage = '';
        this.apiStatus = null;
        const formData = new FormData();
        formData.append('userfile', this.selectedUploadFile);

        OperationsApi.uploadFile(formData).then((response) => {
            this.apiStatus = response.status;
            if(response.status === this.successLegacyUploadStatusCode){
                this.showLegacyUploadMessage = true;
            }
            this.apiUserMessage = response.data as unknown as string;
        }).catch((error) => {
            this.apiStatus = error.response.status;
            if(this.apiStatus === this.invalidLegacyUploadStatusCode){
                this.showLegacyUploadMessage = true;
            }
            this.apiUserMessage = `${error}\n${error.response.data.Message ?? ''}`;
        }).finally(() => ((this.isFetchingData = false), this.resetUploadFile()));
    }
}
