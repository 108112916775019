










import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class ConfirmMessage extends Vue {
  @Prop({ type: String, required: false, default: 'Continue?' }) text?: string;
}
