var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "client-buttons" },
        [
          _c("BaseButton", {
            attrs: {
              tooltipText:
                "Copy " + _vm.clientEntityNameDisplayName + " to clipboard",
            },
            on: {
              click: function ($event) {
                return _vm.copyToClipboard(_vm.clientEntityNameDisplayName)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fad", "file-signature"] },
                    }),
                    _vm._v("  Client Name "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.clientEntityNameDisplayName)),
                    ]),
                    _vm._v("  "),
                    _c("font-awesome-icon", {
                      staticClass: "action-icon",
                      attrs: { icon: ["fal", "copy"] },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("BaseButton", {
            attrs: {
              tooltipText: "Copy " + _vm.clientEntityID + " to clipboard",
            },
            on: {
              click: function ($event) {
                return _vm.copyToClipboard(_vm.clientEntityID)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fad", "fingerprint"] },
                    }),
                    _vm._v("  Client Id "),
                    _c("strong", [_vm._v(_vm._s(_vm.clientEntityID))]),
                    _vm._v("  "),
                    _c("font-awesome-icon", {
                      staticClass: "action-icon",
                      attrs: { icon: ["fal", "copy"] },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("p", [
        _vm._v("Portfolio names must match those in Manager Settings."),
      ]),
      _vm.isLoadingTable
        ? _c("LoadingSpinner")
        : _vm.bulkUploadTable &&
          _vm.bulkUploadTable.entityBenchmarkNames &&
          _vm.bulkUploadTable.entityBenchmarkNames.length
        ? _c(
            "div",
            [
              _c("BaseButton", {
                attrs: {
                  tooltipText:
                    "Copy all " +
                    _vm.clientEntityNameDisplayName +
                    " entities to clipboard",
                },
                on: { click: _vm.copyEntitiesToClipBoard },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fad", "table"] },
                        }),
                        _vm._v("  Copy all table values  "),
                        _c("font-awesome-icon", {
                          staticClass: "action-icon",
                          attrs: { icon: ["fal", "copy"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("table", [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("td", [_vm._v("Invested portfolio")]),
                      _c("td", [_vm._v("Classification")]),
                      _vm.showPrimaryBenchmarkColumn
                        ? _c("td", [_vm._v("Primary benchmark name")])
                        : _vm._e(),
                      _vm._l(
                        _vm.highestSecondaryBenchmarkCount,
                        function (index) {
                          return _c("td", { key: index }, [
                            _vm._v(
                              "Alternative benchmark " + _vm._s(index) + " name"
                            ),
                          ])
                        }
                      ),
                    ],
                    2
                  ),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.bulkUploadTable.entityBenchmarkNames,
                    function (entity, index) {
                      return _c(
                        "tr",
                        { key: entity.entityName + index },
                        [
                          _c(
                            "td",
                            [
                              _c("BaseButton", {
                                staticClass: "text-left",
                                attrs: { tooltipText: "Copy row to clipboard" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyEntityToClipBoard(entity)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "entity-button" },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(entity.entityName)
                                                ),
                                              ]),
                                              _c("font-awesome-icon", {
                                                staticClass: "action-icon",
                                                attrs: {
                                                  icon: ["fal", "copy"],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c("td", [_vm._v(_vm._s(entity.classification))]),
                          _vm.showPrimaryBenchmarkColumn
                            ? _c("td", [
                                _vm._v(_vm._s(entity.primaryBenchmarkName)),
                              ])
                            : _vm._e(),
                          _vm._l(
                            entity.secondaryBenchmarkName,
                            function (secondaryBenchmark) {
                              return _c("td", { key: secondaryBenchmark }, [
                                _vm._v(_vm._s(secondaryBenchmark)),
                              ])
                            }
                          ),
                        ],
                        2
                      )
                    }
                  ),
                  0
                ),
              ]),
            ],
            1
          )
        : _c("p", [_vm._v("No managers found.")]),
      _c(
        "div",
        {
          staticClass: "save-slide-out",
          class: { "show-save": _vm.showCopyToast },
        },
        [
          _c("div", { staticClass: "slide-out-inner flex flex--row-center" }, [
            _vm._v("Copied to clipboard"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }