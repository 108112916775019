









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class ActionText extends Vue {
  @Prop({type:String, required: true}) text: string;

  @Prop({type:String, required: true}) icon: string;

  @Prop({type:String, required:false, default: 'fal' }) iconPrefix?: string;

  @Prop({type:Boolean, required: false, default:false}) disabled?: boolean;
}
