import  { AxiosResponse } from 'axios';
import ApiHelper from './apiHelper';
import fileSave from 'file-saver';

import dayjs from 'dayjs';
import store from '@/store';
import sanitize from 'sanitize-filename';
import { IPortfolioBulkOperationsTableWithEntityTypeDto } from '@/interfaces/dto/IPortfolioBulkOperationsTableWithEntityTypeDto';

const MIME = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; // 'application/vnd.ms-excel';

export default class OperationsApi {
    static async getBulkUploadTableWithEntityType (payload: {clientId: string, portfolioId: number}): Promise<IPortfolioBulkOperationsTableWithEntityTypeDto> {
        return (await ApiHelper.post('GetBulkUploadTableWithEntityType', payload)).data as Promise<IPortfolioBulkOperationsTableWithEntityTypeDto>;
    }

    static async uploadFile (file: FormData): Promise<AxiosResponse<unknown>> {
        return (await ApiHelper.postFile('ProcessMultipleBenchmarkDataLoadWorkbook', file)) as AxiosResponse<unknown>;
    }

    static async downloadCalculationsFile (): Promise<void> {
        const response = await ApiHelper.postExportNavAndPerformanceToCsv('ExportNavAndPerformanceToCsv', {});

        const blob = base64ToBlob(response.data as string);
        const fileName = sanitize(`${store.state.selectedScheme.clientEntityNameDisplayName}-${dayjs().format('YYYY-MM-DD-HHmmss')}.xlsx`);

        fileSave.saveAs(blob, fileName);
    }

    static async downloadPendingFile (): Promise<void> {
        const response = await ApiHelper.post('DownloadSpreadsheet', {});

        const blob = base64ToBlob(response.data as string);
        const fileName = sanitize(`${store.state.selectedScheme.clientEntityNameDisplayName}-${dayjs().format('YYYY-MM-DD-HHmmss')}.xlsx`);

        fileSave.saveAs(blob, fileName);
    }

    static async downloadLiveFile (): Promise<void> {
        const response = await ApiHelper.postLivePortfolioId('DownloadSpreadsheet', {});

        const blob = base64ToBlob(response.data as string);
        const fileName = sanitize(`${store.state.selectedScheme.clientEntityNameDisplayName}-${dayjs().format('YYYY-MM-DD-HHmmss')}.xlsx`);

        fileSave.saveAs(blob, fileName);
    }
}
const base64ToBlob = (val: string) => {
    const bstr = atob(val);
    const ab = s2ab(bstr);
    return new Blob([ab], { type: MIME });
};

const s2ab = (val: string) => {
    const buf = new ArrayBuffer(val.length);
    const view = new Uint8Array(buf);
    // eslint-disable-next-line
    for (let i = 0; i !== val.length; ++i) view[i] = val.charCodeAt(i) & 0xFF;
    return buf;
};
