var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapper",
      class: { disabled: _vm.disabled },
      on: {
        click: function ($event) {
          !_vm.disabled ? _vm.$emit("customclick") : null
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "theme-icon flex flex--row-center" },
        [
          _c("font-awesome-icon", {
            attrs: { icon: [_vm.iconPrefix, _vm.icon] },
          }),
        ],
        1
      ),
      _c("span", { staticClass: "action__text" }, [_vm._v(_vm._s(_vm.text))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }