var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("font-awesome-icon", {
    attrs: { icon: ["fal", "spinner"], spin: true, "aria-label": "loading" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }