var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manager--content" },
    [
      _c("div", { staticClass: "performance" }, [
        _c(
          "div",
          { staticClass: "performance-wrapper" },
          [
            _vm.isFetchingData ? _c("LoadingOverlay") : _vm._e(),
            _vm.showConfirmMessage
              ? _c("BaseModal", {
                  class: { "show-modal": _vm.showConfirmMessage },
                  on: { false: _vm.closeConfirmPopUp },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("confirm-message", {
                              attrs: {
                                text: "If file successfully uploads, it will override all current Client data, confirm that you wish to continue",
                              },
                              on: {
                                false: _vm.closeConfirmPopUp,
                                true: _vm.submitFile,
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3205436719
                  ),
                })
              : _vm._e(),
            _c("div", { staticClass: "bulk-container" }, [
              _c("div", { staticClass: "bulk" }, [
                _c("div", [
                  _c("section", { staticClass: "bulk-operations" }, [
                    _c(
                      "span",
                      {
                        staticClass: "caps",
                        on: {
                          click: function ($event) {
                            _vm.selectedOperation = ""
                          },
                        },
                      },
                      [_vm._v("Bulk Operations:")]
                    ),
                    _c(
                      "ul",
                      _vm._l(_vm.bulkOperations, function (operation, index) {
                        return _c("li", { key: index }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn--discard mt-3",
                              class: {
                                "active-operation":
                                  _vm.selectedOperation === operation.name,
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.selectedOperation = operation.name
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-1",
                                attrs: { icon: operation.icon },
                              }),
                              _vm._v(_vm._s(operation.name) + " "),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _c("section", { staticClass: "bulk-operations mt-5" }, [
                    _c(
                      "span",
                      {
                        staticClass: "caps",
                        on: {
                          click: function ($event) {
                            _vm.selectedOperation = ""
                          },
                        },
                      },
                      [_vm._v("Calculation Operations:")]
                    ),
                    _c(
                      "ul",
                      _vm._l(
                        _vm.calculationOperations,
                        function (operation, index) {
                          return _c("li", { key: index }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn--discard mt-3",
                                class: {
                                  "active-operation":
                                    _vm.selectedOperation === operation.name,
                                },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.selectedOperation = operation.name
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: operation.icon },
                                }),
                                _vm._v(_vm._s(operation.name) + " "),
                              ],
                              1
                            ),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
                _c("section", { staticClass: "bulk-actions" }, [
                  _vm.selectedOperation === "Bulk Upload"
                    ? _c("div", { staticClass: "bulk-actions__upload p-4" }, [
                        _c(
                          "div",
                          { staticClass: "bulk-actions__upload-instructions" },
                          [
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v(
                                "Bulk Upload allows you to populate Manager Data from an excel file."
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "Please note that for the upload to be accepted the excel form must match the following Manager Settings:"
                              ),
                            ]),
                            _c(
                              "bulk-operations-match-details-with-entity-type",
                              {
                                attrs: {
                                  bulkUploadTable: _vm.bulkUploadTable,
                                  isLoadingTable: _vm.isLoadingTable,
                                  client: _vm.client,
                                },
                              }
                            ),
                            _c("p", { staticClass: "upload-warning" }, [
                              _vm._v(
                                "Ensure your excel file is populated with the correct details."
                              ),
                            ]),
                            _c("p", { staticClass: "upload-warning mb-4" }, [
                              _vm._v(
                                "NB. Any file successfully uploaded will override data currently in Manager Data."
                              ),
                            ]),
                            _c(
                              "label",
                              { attrs: { for: "file-upload" } },
                              [
                                _c("action-text", {
                                  staticClass: "action",
                                  attrs: {
                                    iconPrefix: "fas",
                                    icon: "file-excel",
                                    text: !_vm.selectedUploadFileName
                                      ? "Select a file"
                                      : "Selected file " +
                                        _vm.selectedUploadFileName,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("input", {
                              ref: "file",
                              attrs: { id: "file-upload", type: "file" },
                              on: { change: _vm.uploadFile },
                            }),
                            _c("action-text", {
                              staticClass: "action",
                              attrs: {
                                iconPrefix: "fas",
                                icon: "file-upload",
                                text: _vm.uploadText,
                                disabled: !_vm.selectedUploadFileName,
                              },
                              on: { customclick: _vm.confirmSubmitFile },
                            }),
                          ],
                          1
                        ),
                        _vm.apiUserMessage
                          ? _c(
                              "div",
                              { staticClass: "bulk-actions__upload-response" },
                              [
                                _c("h4", { staticClass: "mb-3" }, [
                                  _vm._v(" Database response: "),
                                  _vm.apiStatus === 200
                                    ? _c(
                                        "span",
                                        { staticClass: "ml-3" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "success",
                                            attrs: {
                                              icon: ["far", "file-check"],
                                            },
                                          }),
                                          _vm._v(
                                            " Upload successful - see below for details"
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.apiStatus ===
                                      _vm.successLegacyUploadStatusCode
                                    ? _c(
                                        "span",
                                        { staticClass: "ml-3" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "success",
                                            attrs: {
                                              icon: ["far", "file-exclamation"],
                                            },
                                          }),
                                          _vm._v(
                                            " Upload successful - legacy template used - see below for details"
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.apiStatus === 400
                                    ? _c(
                                        "span",
                                        { staticClass: "ml-3" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "warning",
                                            attrs: {
                                              icon: ["far", "file-exclamation"],
                                            },
                                          }),
                                          _vm._v(
                                            " Upload unsuccessful - validation error - see below for details"
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.apiStatus ===
                                      _vm.invalidLegacyUploadStatusCode
                                    ? _c(
                                        "span",
                                        { staticClass: "ml-3" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "error",
                                            attrs: {
                                              icon: [
                                                "far",
                                                "exclamation-circle",
                                              ],
                                            },
                                          }),
                                          _vm._v(
                                            " Upload unsuccessful - unsupported legacy template used"
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.apiStatus === 500
                                    ? _c(
                                        "span",
                                        { staticClass: "ml-3" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "error",
                                            attrs: {
                                              icon: [
                                                "far",
                                                "exclamation-circle",
                                              ],
                                            },
                                          }),
                                          _vm._v(
                                            " Internal server error - see below for details"
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "ml-3" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "error",
                                            attrs: {
                                              icon: [
                                                "far",
                                                "exclamation-circle",
                                              ],
                                            },
                                          }),
                                          _vm._v(" Unknown error"),
                                        ],
                                        1
                                      ),
                                ]),
                                _c("div", {
                                  staticClass: "db-response pre border",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.apiUserMessage),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm.selectedOperation === "Bulk Download"
                    ? _c("div", { staticClass: "bulk-actions__download p-4" }, [
                        _c("h4", { staticClass: "mb-4" }, [
                          _vm._v(
                            "Bulk Download gives you all relevant Manager Data in a single file."
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("action-text", {
                              staticClass: "action",
                              attrs: {
                                iconPrefix: "fas",
                                icon: "file-download",
                                text: "Download file",
                              },
                              on: { customclick: _vm.downloadPendingFile },
                            }),
                          ],
                          1
                        ),
                        _vm.client && _vm.client.livePortfolioId
                          ? _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c("action-text", {
                                  staticClass: "action",
                                  attrs: {
                                    iconPrefix: "fas",
                                    icon: "file-download",
                                    text: "Download Live file",
                                  },
                                  on: { customclick: _vm.downloadLiveFile },
                                }),
                                _c("font-awesome-icon", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "This is the Approved/Live data",
                                      expression:
                                        "'This is the Approved/Live data'",
                                    },
                                  ],
                                  staticClass: "fa-bolt",
                                  attrs: { icon: ["fad", "bolt"] },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm.selectedOperation === "Template Download"
                    ? _c(
                        "div",
                        { staticClass: "bulk-actions__template p-4" },
                        [
                          _c("h4", { staticClass: "mb-4" }, [
                            _vm._v(
                              "Download an excel template file to build your data."
                            ),
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "download-link",
                              attrs: {
                                href:
                                  "/assets/files/" +
                                  _vm.templateDownloadFile +
                                  ".xlsm",
                                download: "",
                              },
                            },
                            [
                              _c("action-text", {
                                staticClass: "action",
                                attrs: {
                                  iconPrefix: "fas",
                                  icon: "file-download",
                                  text: "Download Monitor and Visualise Data Load Sheet Template",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._m(0),
                          _c("p", { staticClass: "mt-3" }, [
                            _vm._v(
                              "You will need to match the following Manager Settings in the form:"
                            ),
                          ]),
                          _c("bulk-operations-match-details-with-entity-type", {
                            attrs: {
                              bulkUploadTable: _vm.bulkUploadTable,
                              isLoadingTable: _vm.isLoadingTable,
                              client: _vm.client,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.selectedOperation === "Calculation Data Download"
                    ? _c("div", { staticClass: "bulk-actions__download p-4" }, [
                        _vm.isCalculationDataFeatureLive
                          ? _c("div", [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v(
                                  "Download your calculation data to have all your relevant data in a single file."
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("action-text", {
                                    staticClass: "action",
                                    attrs: {
                                      iconPrefix: "fas",
                                      icon: "file-download",
                                      text: "Download file",
                                    },
                                    on: {
                                      customclick:
                                        _vm.downloadCalculationDataFile,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                " Coming soon... You'll soon be able to download all your calculation data in one file... "
                              ),
                            ]),
                      ])
                    : _c("div", { staticClass: "p-4" }, [
                        _c("h4", { staticClass: "mb-3" }, [
                          _vm._v("Quickly populate and view your Manager Data"),
                        ]),
                        _c("p", { staticClass: "mb-3" }, [
                          _vm._v(
                            " Use Bulk Operations to upload a file, view your data with Bulk Download; or to populate your data, download an excel template file. "
                          ),
                        ]),
                        _c("p", { staticClass: "mb-3" }, [
                          _vm._v(
                            "Use Calculation Operations to download your calculation data."
                          ),
                        ]),
                        _vm._m(1),
                      ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _vm.showLegacyUploadMessage
        ? _c("BaseModal", {
            class: { "show-modal": _vm.showLegacyUploadMessage },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "legacy-upload-message" }, [
                        _c("h2", [_vm._v(_vm._s(_vm.legacyUploadHeading))]),
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.legacyUploadMessage),
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn--default",
                            on: {
                              click: function ($event) {
                                _vm.showLegacyUploadMessage = false
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3446525635
            ),
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "save-slide-out",
          class: { "show-save": _vm.showCopyToast },
        },
        [
          _c("div", { staticClass: "slide-out-inner flex flex--row-center" }, [
            _vm._v("Copied to clipboard"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" Once downloaded, you can follow the instructions on the "),
      _c("em", [_vm._v("instructions")]),
      _vm._v(" tab. "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Please select an Operation")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }