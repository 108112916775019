

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { IEntityBenchmarkNameWithEntityTypeDto, IPortfolioBulkOperationsTableWithEntityTypeDto } from '@/interfaces/dto/IPortfolioBulkOperationsTableWithEntityTypeDto';
import Client from '@/models/Client';
import BaseButton from '@/components/BaseElements/BaseButton.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

@Component({
    components: {
        BaseButton,
        LoadingSpinner
    },
})export default class BulkOperationsMatchDetails extends Vue {
    @Prop({ type: [Object, null], required: false, default: null }) public bulkUploadTable: IPortfolioBulkOperationsTableWithEntityTypeDto | null;

    @Prop({ type: Boolean, required: false, default: true }) public isLoadingTable: boolean;

    @Prop({ type: Object, required: false, default: null }) public client: Client | null;


    get showPrimaryBenchmarkColumn (): boolean {
        return this.bulkUploadTable?.entityBenchmarkNames?.some(row => row.primaryBenchmarkName !== null) ?? false;
    }

    get clientEntityNameDisplayName (): string {
        return this.client?.clientEntityNameDisplayName ?? '';
    }

    get clientEntityID (): string {
        return this.client?.clientEntityID ?? '';
    }

    public isSetTimeout = false;

    public showCopyToast = false;


    get highestSecondaryBenchmarkCount () :number {
        let max = 0;
        this.bulkUploadTable?.entityBenchmarkNames?.forEach(row => {
            if (row.secondaryBenchmarkName.length > max) {
                max = row.secondaryBenchmarkName.length;
            }
        });
        return max;
    }


    copySingleRow (text: string): void {
        const rowText = this.addRowToClipboard(text);
        this.copyToClipboard(rowText);
    }

    addRowToClipboard (text: string): string {
        return text + String.fromCharCode(13);
    }

    addTabCharacterToClipboard (text: string | number | boolean): string {
        return text + String.fromCharCode(9);
    }

    cleanBenchmarkName (benchmark: string): string {
        if (this.bulkUploadTable?.benchmarkNamesToExcludeFromCopying?.some(benchmarkName => benchmarkName === benchmark)) {
            return '';
        }
        return benchmark;
    }

    // copy an entity to clipboard
    copyEntityToClipBoard (entity: IEntityBenchmarkNameWithEntityTypeDto): void{
        const result =  this.formatEntityForClipboard(entity);
        this.copyToClipboard(result);

    }

    formatEntityForClipboard (entity: IEntityBenchmarkNameWithEntityTypeDto): string {
        const secondaryBenchmarkCount = entity.secondaryBenchmarkName.length;
        let result = '';
        result += this.addTabCharacterToClipboard(entity.entityName);
        result += this.addTabCharacterToClipboard(this.cleanBenchmarkName(entity.primaryBenchmarkName) ?? '');
        entity.secondaryBenchmarkName.forEach(benchmark => {
            result += this.addTabCharacterToClipboard(this.cleanBenchmarkName(benchmark));
        });
        if (secondaryBenchmarkCount === 0) {
            result += this.addTabCharacterToClipboard('');
            result += this.addTabCharacterToClipboard('');
        }
        if (secondaryBenchmarkCount === 1) {
            result += this.addTabCharacterToClipboard('');
        }
        result += entity.classification;
        result += String.fromCharCode(13);
        return result;
    }

    copyEntitiesToClipBoard (): void{
        let result = '';
        this.bulkUploadTable?.entityBenchmarkNames?.forEach(entity => {
            result += this.formatEntityForClipboard(entity);
        });
        this.copyToClipboard(result);
    }


    copyToClipboard (text: string): void {
        if (!this.isSetTimeout) {
            this.showCopyToast = true;
            this.isSetTimeout = true;

            setTimeout(() => {
                this.showCopyToast = false;
                this.isSetTimeout = false;
            }, 1000);

            navigator.clipboard.writeText(text);
        }
    }
}

